import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Footer from './Footer'
import '../../stylesheets/main.scss'

const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 40px;
  max-width: 944px;
  margin: 0 auto;

  @media screen and (max-width: 599px){
    width: 100%;
    padding: 0;
  }
`

const SiteContainerInner = styled.div`
  padding: 40px 0 0;
  flex: 1 1 auto;
`

const Layout = ({
  data,
  title = '',
  noIndex = false,
  children,
}) => {
  const {
    description,
  } = data.prismicSitemeta.data

  const seoTitle = title ? `${title} | ${data.prismicSitemeta.data.title}` : data.prismicSitemeta.data.title

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
        prioritizeSeoTags
      >
        <meta charset="utf-8" />
        <title>{seoTitle}</title>
        <meta name="description" content={description} />
        <meta name="google-site-verification" content="LQwifRWH7-CyK2O5k5HNjfM5NMNjQCNj_w-qdS8ooOM" />
        <meta name="google-site-verification" content="cPfMmsMrdAfrqLvhArAYTJv2ZQKXUPVFMduNEdOjjfE" />
      </Helmet>
      { noIndex && <GatsbySeo noindex nofollow /> }
      <SiteContainer>
        <SiteContainerInner>
          { children }
        </SiteContainerInner>
        <Footer />
      </SiteContainer>
    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        prismicSitemeta {
          id
          data {
            description
            title
          }
        }
      }
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(data) => <Layout data={data} {...props} />}
  />
)
