import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 599px){
    padding: 0 30px;
  }
`

export default Container
