import React from 'react'
import styled from 'styled-components'
import favicon from '../../images/favicon.svg'

const Footer = styled.footer`
  flex: 0 0 auto;
  padding: 10px 0;
`

const FooterInner = styled.div`
  display: flex;
  width: 240px;
  font-size: .7rem;
  justify-content: space-evenly;
  position: relative;
  padding: 20px 0 10px;

  &::before{
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ddd;
  }
`

const FooterContent = styled.div`
  flex: 0 0 auto;
  margin: 5px;
`

export default () => (
  <Footer>
    <FooterInner>
      <FooterContent>
        <img src={favicon} width="12" height="12" alt="logo" />
      </FooterContent>
      <FooterContent>
        { /* eslint-disable-next-line react/jsx-one-expression-per-line */ }
        powered by <a href="https://prismic.io" target="_blank" rel="noopener noreferrer">prismic</a> + <a href="https://www.gatsbyjs.com" target="_blank" rel="noopener noreferrer">Gatsby</a> + <a href="https://www.netlify.com" target="_blank" rel="noopener noreferrer">Netlify</a>
      </FooterContent>
    </FooterInner>
  </Footer>
)
